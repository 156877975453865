<template>
  <div id="correlativo-seguimiento">
    <v-container grid-list-xl fluid>
      <header-title title="Correlativo Seguimiento"></header-title>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            label="Gestión"
            :items="gestiones"
            v-model="gestion"
            @change="getSeguimiento"
          ></v-select>
        </v-col>
        <!-- <v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showStatistic">
            <v-icon>mdi-image-outline</v-icon>Gráfica
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="correlativos"
            :loading="loading"
            :search="search"
            no-data-text="No existen registros"
            no-results-text="Sin resultados"
            item-key="name"
            class="elevation-1"
            v-cloak
          >
            <template v-slot:item.fecha="{ item }">
              <td>
                {{ item.fecha_registro | formatdate }}
              </td>
            </template>
            <template v-slot:item.estado="{ item }">
              <td>
                <span v-if="item.estado == 1">
                  <v-chip small color="green lighten-4">activo</v-chip>
                </span>
                <span v-else>
                  <v-chip small color="red lighten-4">inactivo</v-chip>
                </span>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="blue"
                      @click="showConfirm(item.id, item.estado)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-shuffle-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Cambiar estado</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="mdialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteItem()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="mdialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
  /* eslint-disable no-console */
  import HeaderTitle from "@/components/HeaderTitle";
  import Service from "../services/general";
  import axios from "axios";
  export default {
    name: "correlativo-seguimiento",
    components: {
      HeaderTitle,
    },
    data(vm) {
      return {
        search: "",
        loading: false,
        btn_loading: false,
        headers: [
          // { text: "#", value: "nro", align: "left", sortable: false },
          { text: "Hoja de ruta ", value: "hoja_ruta" },
          { text: "Tipo informe ", value: "tipo_informe" },
          { text: "Correlativo ", value: "numero" },
          { text: "Descripción ", value: "descripcion" },
          { text: "Fecha ", value: "fecha" },
          { text: "Responsable ", value: "responsable" },
          { text: "Estado ", value: "estado" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        correlativo: { id: "", estado: false },
        correlativos: [],
        gestiones: [],
        gestion: "",
        mdialog: false,
        snack: {
          state: false,
          color: "success",
          mode: "",
          timeout: 2500,
          text: "",
        },
      };
    },

    mounted() {
      if (Service.getUser()) {
        if (Service.getUser().role == 1) {
          this.gestion = new Date().getFullYear();
          for (let index = this.gestion; index >= 2015; index--) {
            this.gestiones.push(index);
          }
          this.getSeguimiento();
        } else {
          this.$router.replace({ name: "dashboard" });
        }
      }
    },
    methods: {
      getSeguimiento() {
        this.loading = true;
        this.correlativos = [];
        axios
          .post(
            Service.getBase() + "lista/correlativo",
            { gestion: this.gestion },
            Service.getHeader()
          )
          .then(response => {
            this.loading = false;
            this.correlativos = response.data;
          })
          .catch(error => {
            this.loading = false;
            console.error("Error al cargar registros", error);
          });
      },
      showConfirm(correlativo_id, estado) {
        this.correlativo.id = correlativo_id;
        this.correlativo.estado = !!estado;
        this.mdialog = true;
      },
      deleteItem() {
        this.btn_loading = true;
        axios
          .put(
            Service.getBase() + "cambiar/correlativo/" + this.correlativo.id,
            Service.getHeader()
          )
          .then(() => {
            this.btn_loading = false;
            this.toast(
              "success",
              this.correlativo.estado
                ? "Correlativo inactivado"
                : "Correlativo activado"
            );
            this.mdialog = false;
            this.getSeguimiento();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "Correlativo no existente");
          });
      },
      toast(mcolor, mtext) {
        this.snack.color = mcolor;
        this.snack.text = mtext;
        this.snack.state = true;
      },
    },
  };
</script>
